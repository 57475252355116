<template>
  <el-card class="box-card">
    <el-tabs v-model="tabs">
      <el-tab-pane label="拼团商品" name="tab_1">
        <el-col :span="12" style="margin-top: 10px">
          <el-button icon="el-icon-plus" type="warning" @click="handleEdit" size="small">添加拼团商品
          </el-button>
        </el-col>
        <el-col :span="12" style="margin-top: 10px; text-align: right">
          <el-form :inline="true" :model="queryForm" @submit.native.prevent>
            <el-form-item label="活动时间:">
              <el-date-picker
                  v-model="activity_time"
                  size="small"
                  :default-time="['00:00:00', '23:59:59']"
                  end-placeholder="结束日期"
                  format="yyyy-MM-dd HH:mm:ss"
                  range-separator="至"
                  start-placeholder="开始日期"
                  type="daterange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  @change="getActivityTime"
              />
            </el-form-item>
            <el-form-item>
              <el-button icon="el-icon-search" @click="queryData" type="primary" size="small">
                查询
              </el-button>
            </el-form-item>
          </el-form>
        </el-col>

        <el-col :span="24">
          <el-radio-group v-model="queryForm.type" @change="buttonTab">
            <el-radio-button type="button"
                             v-for="item in button_state"
                             :key="item.id"
                             :label="item.id"
            >
              {{ item.name }}
            </el-radio-button>
          </el-radio-group>
        </el-col>

        <el-table
            :data="list"
            @selection-change="setSelectRows">
          <el-table-column align="right" label="" show-overflow-tooltip>
            <template #default="{ row }">
              <el-image v-if="imgShow" :src="row.pic" class="commodity_pic"/>
            </template>
          </el-table-column>
          <el-table-column align="left" label="拼团" show-overflow-tooltip>
            <template #default="{ row }">
              <div>{{ row.name }}</div>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              prop="price"
              label="拼团价格">
          </el-table-column>
          <el-table-column
              align="center"
              prop="number_of_open_groups"
              label="开团组数">
          </el-table-column>
          <el-table-column
              align="center"
              prop="number_of_groups"
              label="成团组数">
          </el-table-column>
          <el-table-column
              align="center"
              prop="number_of_buyers"
              label="购买人数">
          </el-table-column>
          <el-table-column
              align="center"
              label="活动时间"
              prop="create_at"
              show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>开始时间{{ scope.row.create_at | formatDate }}</div>
              <div>结束时间{{ scope.row.end_at | formatDate }}</div>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              prop="state"
              :formatter="formatterState"
              label="状态">
          </el-table-column>
          <el-table-column align="center" label="操作" width="120">
            <template #default="{ row }">
              <!--              <el-button type="text" @click="">查看</el-button>-->
              <el-button type="text" @click="handleEdit(row)">修改</el-button>
              <el-button type="text" @click="handleDelete(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            :current-page="queryForm.page"
            :layout="layout"
            :page-size="queryForm.pageSize"
            :total="count"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
        />
      </el-tab-pane>

      <el-tab-pane label="拼团列表" name="tab_2">
        <el-col :span="24" style="margin-top: 10px; text-align: right">
          <el-form :inline="true" :model="queryForm" @submit.native.prevent>
            <el-form-item label="商品名称:" prop="snapshot_product_title">
              <el-input v-model="queryForm.snapshot_product_title" clearable size="small" placeholder="请输入商品名称"/>
            </el-form-item>
            <el-form-item label="团长昵称:" prop="sponsor">
              <el-input v-model="queryForm.sponsor" clearable size="small" placeholder="请输入团长名称"/>
            </el-form-item>
            <el-form-item label="开团时间:">
              <el-date-picker
                  v-model="query_time"
                  size="small"
                  type="daterange"
                  :picker-options="pickerOptions"
                  @change="getTimeSection"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  align="right">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="拼团状态:" prop="state">
              <el-select v-model="queryForm.state" clearable size="small">
                <el-option label="拼团成功" value="0"></el-option>
                <el-option label="拼团失败" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button icon="el-icon-search" @click="queryData_a" type="primary" size="small">
                查询
              </el-button>
              <el-button @click="resetForm('searchForm')" type="info" size="small">重置</el-button>
            </el-form-item>
          </el-form>
        </el-col>

        <el-table :data="list_a">
          <el-table-column
              align="center"
              prop="user_id"
              label="团长信息">
          </el-table-column>
          <el-table-column align="center" label="商品图" show-overflow-tooltip>
            <template #default="{ row }">
              <el-image v-if="imgShow" :src="row.pic" class="commodity_pic"/>
              <el-image v-else src="#" class="pic_size"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="拼团商品" show-overflow-tooltip>
            <template #default="{ row }">
              <div>{{ row.name }}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="开团时间">
            <template slot-scope="scope">
              <div>{{ scope.row.launch_at | formatDate }}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="成团人数 / 当前人数" show-overflow-tooltip width="200px">
            <template #default="{ row }">
              <div>{{ row.group_size }} / {{ row.current_number }}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="结束时间">
            <template slot-scope="scope">
              <div>{{ scope.row.end_at | formatDate }}</div>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              prop="state"
              :formatter="formatterStateA"
              label="状态">
          </el-table-column>
          <el-table-column align="center" label="操作" width="80">
            <template #default="{ row }">
              <el-button type="text" @click="groupDetails(row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            :current-page="queryForm_a.page"
            :layout="layout_a"
            :page-size="queryForm_a.pageSize"
            :total="count_a"
            @current-change="handleCurrentChange_a"
            @size-change="handleSizeChange_a"
        />
      </el-tab-pane>
    </el-tabs>
    <Edit ref="Edit"/>
  </el-card>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import Edit from './components/Edit'

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    },
  },
  components: {Edit},
  props: [],
  data() {
    return {
      tabs: 'tab_1',
      // !!拼团商品
      groupProductSearch: '1',
      activity_time: [],
      imgShow: true,
      current: 0,
      button_state: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '未开始'
        },
        {
          id: 2,
          name: '进行中'
        },
        {
          id: 3,
          name: '已结束'
        },
        {
          id: 4,
          name: '已失效'
        }
      ],
      selectRows: '',
      // listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        type: 0,
      },
      list: [
        {
          id: '1',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          end_at: '1647584613',
          merchant_id: '1',
          is_check: '0',
        },
        {
          id: '2',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          end_at: '1647584613',
          merchant_id: '1',
          is_check: '1',
        },
        {
          id: '3',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '1',
          create_at: '1647584613',
          end_at: '1647584613',
          merchant_id: '1',
          is_check: '2',
        },
      ],
      // !!拼团商品

      // !!拼团列表
      activeName: '1',
      query_time: [],
      pickerOptions: {
        shortcuts: [{
          text: '近7天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近30天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      layout_a: 'total, sizes, prev, pager, next, jumper',
      count_a: 0,
      queryForm_a: {
        page: 1,
        pageSize: 10,
      },
      list_a: [
        {
          id: '1',
          user_id: '123',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          end_at: '1647584613',
          merchant_id: '1',
          is_check: '0',
        },
        {
          id: '2',
          user_id: '123',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          end_at: '1647584613',
          merchant_id: '1',
          is_check: '1',
        },
        {
          id: '3',
          user_id: '123',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '1',
          create_at: '1647584613',
          end_at: '1647584613',
          merchant_id: '1',
          is_check: '2',
        },
      ],
      // !!拼团列表
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
    this.fetchData_a()
  },
  mounted() {
  },
  methods: {
    // !!砍价商品
    // 获取时间段
    getActivityTime(event) {
      console.log(event)
      if (event != null) {
        this.queryForm.start_date = event[0]
        this.queryForm.end_date = event[1]
      } else {
        this.queryForm.start_date = ''
        this.queryForm.end_date = ''
      }
    },
    // tab切换
    buttonTab(num) {
      if (num == 1) {
        this.current = 1;
        this.queryForm.type = 1
      } else if (num == 2) {
        this.current = 2;
        this.queryForm.type = 2
      } else if (num == 3) {
        this.current = 3;
        this.queryForm.type = 3
      } else if (num == 4) {
        this.current = 4;
        this.queryForm.type = 4
      } else {
        this.current = 0;
        this.queryForm.type = ''
      }
      this.fetchData()
    },
    bargainItemSettings() {
      this.$router.push({
        path: 'bargainItemSettings',
      })
    },
    handleEdit(row) {
      if (row.id) {
        this.$router.push({
          path: 'collage/revise',
          query: {
            id: row.id,
          },
        })
      } else {
        this.$router.push({
          path: 'collage/revise',
        })
      }
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要删除当前项吗', null, async () => {
          const {code, message} = await doDelete({id: row.id})
          if (code == '200') {
            this.$message.success(message)
            await this.fetchData()
          } else {
            this.$message.error(message)
            await this.fetchData()
          }
        })
      } else {
        if (this.selectRows.length > 0) {
          const id = this.selectRows.map((item) => item.id).join()
          this.$confirm('你确定要删除选中项吗', null, async () => {
            const {code, message} = await doDelete({id})
            if (code == '200') {
              this.$message.success(message)
              await this.fetchData()
            } else {
              this.$message.error(message)
              await this.fetchData()
            }
          })
        } else {
          this.$message.error('未选中任何行')
          return false
        }
      }
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      const {data} = await getList(this.queryForm)
      this.list = data.list
      this.count = data.count
      this.count = parseInt(data.count)
      if (!this.list.length) this.queryForm.page++
      this.listLoading = false
    },
    // 状态
    formatterState: function (row) {
      return row.state == '0' ? '未开始' : row.state == '1' ? '进行中' : row.state == '2' ? '已结束' : row.state == '3' ? '已失效' : '暂无'
    },
    // !!拼团商品

    // !!拼团列表
    //获取时间段
    getTimeSection(event) {
      console.log(event)
      if (event != null) {
        this.queryForm_a.start_date = event[0]
        this.queryForm_a.end_date = event[1]
      } else {
        this.queryForm_a.start_date = ''
        this.queryForm_a.end_date = ''
      }
    },
    resetForm(formName) {
      this.$refs[formName].getList();
    },
    groupDetails(row) {
      if (row.id) {
        this.$router.push({
          path: 'groupDetails',
          query: {
            id: row.id,
          },
        })
      }
    },
    handleSizeChange_a(val) {
      this.queryForm.pageSize = val
      this.fetchData_a()
    },
    handleCurrentChange_a(val) {
      this.queryForm.page = val
      this.fetchData_a()
    },
    queryData_a() {
      this.queryForm.page = 1
      this.fetchData_a()
    },
    async fetchData_a() {
      this.listLoading = true
      const {data} = await getList(this.queryForm)
      this.list_a = data.list
      this.count_a = data.count
      this.count_a = parseInt(data.count)
      if (!this.list.length) this.queryForm.page++
      this.listLoading = false
    },
    // 状态
    formatterStateA: function (row) {
      return row.state == '0' ? '拼团成功' : row.state == '1' ? '拼团失败' : '暂无'
    },
    // !!拼团列表
  }
}

</script>
<style>
.commodity_pic {
  width: 60px;
  height: 60px;
}
</style>
